<template>
  <ws-dialog
      v-model="$store.state.avalonPage.displayNewsDialog"
      @save="saveNews"
      :title="dialogTitle"
      :save-text="$store.state.avalonPage.newNews ? $t('Create') : $t('Save')"
      fullscreen-width="400"
      fullscreen-align="left"
      not-centered
      fullscreen
      portal

  >
    <ws-text-field
        v-model="entityData.title"
        :label="$t('Title')"
        :placeholder="$t('EnterName')"
    />

    <ws-text-field
        class="mt-6"
        v-model="entityData.description"
        max-chars="300"
        :label="$t('ShortDescription')"
        :placeholder="$t('EnterShortDescription')"
        area
    />

    <ws-select
        v-model="entityData.design"
        :items="newsDesignsSelect"
        :label="$t('Design')"
        :placeholder="$t('Default')"
        class="mb-5"
    />


    <h5 style="color : #4F5A6E; font-size: 13px; font-weight: 500 " class="mb-2" >  {{  $t('Category')  }} </h5>
    <div @click="displayEditCategory = true">
      <ws-select
          style="pointer-events: none"
          class="pointer "
          :class="[{'mb-4' : displayEditCategory}]"
          :value="entityData.categories"
          :items="categoriesSelect"
          :placeholder="$t('Selectcategory')"
          hide-details
          multiple
      />

    </div>

    <v-expand-transition>
      <news-category-selector
          v-if="displayEditCategory"
          @select="selectCategory"
          v-model="entityData.categories"
          @close="displayEditCategory = false"
      />
    </v-expand-transition>


    <image-param-picker
        class="mt-5"
        :label="$t('NewsImage')"
        v-model="entityData.img"
    />

    <ws-text-field
        class="mt-5"
        v-model="entityData.alias"
        :label="$t('Alias')"
        :placeholder="aliasPlaceholder"
        :error="aliasError"
        @input="aliasError = false"
        :tooltip="`${$t('NewsWillBeAvailableByLink')} : ${businessPublicLink('' , true)}/news/${entityData.alias || aliasPlaceholder}`"
    />

    <v-row no-gutters  class="mt-6">
      <v-col cols="6" class="pr-1">
        <ws-date-picker
            v-model="selectedDate"
            :label="$t('DatePublished')"
            :placeholder="datePlaceholder"
        />
      </v-col>
      <v-col cols="6" class="pl-1">
        <ws-select
            :items="GET_TIME_SELECT(10,false)"
            v-model="selectedTime"
            :label="$t('mailer.wizard.settings.time.time_title')"
            :placeholder="selectedTime || $t('mailer.wizard.settings.time.time_placeholder')"
            clearable
            autocomplete
        />
      </v-col>
    </v-row>




  </ws-dialog>
</template>

<script>

import {mapActions, mapState} from "vuex";
import newsCategorySelector from "@/components/AvalonEditor/Dialogs/Page/UI/newsCategorySelector.vue";

export default {
  name: "avalonNewNewsDialog",
  components : {
    imageParamPicker : () => import("@/components/AvalonEditor/UI/imageParamPicker"),
    newsCategorySelector
  },
  data() {
    return {
      displayEditCategory : false,
      categoryEntityData : {
      },
      entityData : {
        categories : []
      },
      aliasPlaceholder : '',
      datePlaceholder  : '',
      displayDeleteCategory : false,
      aliasError : false,

      bufferAlias : null,

      selectedTime : null,
      selectedDate : null,
    }
  },
  computed : {
    ...mapState('avalon' , [
      'selectedNews' ,
      'selectedLang' ,
    ]),
    ...mapState('avalonPage' , [
      'selectedNewsAlias',
      'newNews',
    ]),
    ...mapState('news' , [
      'categoriesSelect',
    ]),

    newsDesignsSelect() {
      return [
        { text : this.$t('Type') + ' 1' , value :'default' },
        { text : this.$t('Type') + ' 2', value :'second' },
      ]
    },

    dialogTitle() {
      if ( this.$store.state.avalonPage.copyNews ) {
        return this.$t('CopyNews')
      }
      if ( this.$store.state.avalonPage.newNews) {
        return this.$t('AddNews')
      }
      return this.$t('EditNews')
    },
  },
  methods : {
    ...mapActions('news', [
      'GET_ADMIN_BUSINESS_NEWS_ONE',
      'ADD_EDIT_BUSINESS_NEWS',
      'GET_NEWS_CATEGORIES',
      'ADD_EDIT_NEWS_CATEGORY',
      'DELETE_NEWS_CATEGORY',
      'CHECK_NEWS_ALIAS',
    ]),
    selectCategory(item) {
      if ( !this.entityData.categories.includes(item.value) ) {
        this.entityData.categories.push(item.value)
      } else {
        let index = this.entityData.categories.findIndex(el => el === item.value )
        this.entityData.categories.splice(index , 1)
      }
    },



    async saveNews() {
      if ( this.entityData.alias && this.entityData.alias !== this.bufferAlias ) {
        let result = await this.CHECK_NEWS_ALIAS(this.entityData.alias)
        if ( !result ) {
          this.aliasError = true
          return this.notify(this.$t('NewsWithAliasExists'), 'warning')
        }
      }
      this.entityData.lang = this.selectedLang




      if (this.selectedDate) {
        this.selectedTime = this.selectedTime || this.$time().format('hh:mm')
        const startDate =  this.$time(this.selectedDate ? `${this.selectedDate} ${this.selectedTime}` : null);
        this.entityData.date = startDate.YMDT()
      }


      if ( this.$store.state.avalonPage.copyNews ) {
        this.entityData.copy_source = this.selectedNewsAlias
      }
      let result = await this.ADD_EDIT_BUSINESS_NEWS(this.entityData)
      if (!result) {
        this.ERROR()
        return
      }

      if ( this.$store.state.avalonPage.newNews ) {
        this.notify(this.$t('NewsDraftCreated'))
        this.$store.state.avalon.newsSelect.unshift(result)
        this.$router.push(this.businessPublicLink(`news/${result.alias}/editor`))
      } else {
        let index = this.$store.state.avalon.newsSelect.findIndex(el => el.uuid === result.uuid )
        if ( index === -1 ) {
          return
        }
        this.$store.state.avalon.newsSelect[index] = result
        this.$store.state.avalon.newsSelect = this.COPY(this.$store.state.avalon.newsSelect)
        this.notify(this.$t('ChangesSaved'))
      }

      this.$store.state.avalonPage.displayNewsDialog = false

    },

    async loadPage() {
      if ( this.newNews ) { return }
      let result  = await this.GET_ADMIN_BUSINESS_NEWS_ONE({alias : this.selectedNewsAlias , lang : this.selectedLang})
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.entityData = result
      this.bufferAlias = result.alias

      if ( this.entityData.date ) {
        let split = this.entityData.date.split(' ')
        this.selectedDate = split[0]
        this.selectedTime = split[1]
      }

    },

  },
  mounted() {
    if ( this.categoriesSelect.length === 0 ) {
      this.GET_NEWS_CATEGORIES(this.selectedLang)
    }

    this.loadPage()
  },
  beforeMount() {
    this.aliasPlaceholder = this.$uuid.v4()
    this.datePlaceholder  = this.PARSE_DATE(new Date() ,true)
  },
  beforeDestroy() {
    this.$store.state.avalonPage.newNews = false
    this.$store.state.avalonPage.copyNews = false
    this.$store.state.avalonPage.selectedNewsAlias = false
  }
}
</script>

<style scoped>

</style>