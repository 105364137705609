<template>
<div>
  <v-hover
      v-for="(item , i) in items" :key="i"
      #default="{hover}"
  >
    <v-sheet
        @click="handleSelect(item)"
        class="px-3 py-1 d-flex justify-space-between align-center wsRoundedLight mb-2 pointer"
        :style="`border : 1px solid ${wsBACKGROUND}`"
    >

      <div class="d-flex align-center">
        <ws-check-box
            prevent-select
            :semi-selected="checkSemiSelection(item)"
            @prevent="handleSelect(item)"
            :value="checkSelection(item)"
        />
        <h5 style="font-size : 14px"> {{ item.text }}</h5>
      </div>

      <div style="min-height: 36px">
        <v-fade-transition>
          <v-btn v-if="hover" @click.stop="$emit('edit',item)" icon :color="wsACCENT">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fade-transition>
      </div>
    </v-sheet>
  </v-hover>

  <ws-button
      class="mt-4"
      :label="!child ? 'AddCategory' : 'AddSubCategory'"
      @click="$emit('open-new')"
      block
  />
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "newsCategoriesList",
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    child : {
      type : Boolean,
      default : false
    },
    selectedCategories : {
      type : Array,
      default() { return [] }
    }
  },
  computed : {
    ...mapState('news' , [
      'categoriesSelect',
    ]),
  },
  methods : {
    handleSelect(item) {
      if ( this.getChildCategories(item.value).length > 0 ) {
        this.$emit('edit' , item)
        return
      }
      this.$emit('select' , item)
    },
    getChildCategories(uuid) {
      return this.categoriesSelect.filter(el => el.parent === uuid )
    },

    checkSelection (item) {
      if ( !this.getChildCategories(item.value).length) {
        return this.selectedCategories.includes(item.value)
      }

      const children = this.getChildCategories(item.value)
      const selected = children.filter(el => this.selectedCategories.includes(el.value) )
      console.log(item.text ,  selected , this.getChildCategories(item.value))
      return selected.length === this.getChildCategories(item.value).length
    },
    checkSemiSelection(item) {
      if ( this.checkSelection(item)) {
        return false
      }
      return this.categoriesSelect
          .filter(el => el.parent === item.value)
          .filter(el => this.selectedCategories.includes(el.value))
          .length > 0
    }
  }
}
</script>

<style scoped>

</style>